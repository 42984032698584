import {Action} from '@ngrx/store';

export const Update_Clinet = 'Update_Clinet';

const initialState = {
    update_client: false
};

export function ClientReducer(state = initialState, action: Action) {
    switch (action.type) {
        case Update_Clinet:
            return action.payload;

        default:
            return state;
    }
}