import {Action} from '@ngrx/store';
export const START_TIMER = 'START_TIMER';
const initialState = {
    startTimer: false,
    logoutTime: 5,
    timeoutInterval: 5000
};
export function TimerReducer(state = initialState, action: Action) {
    switch (action.type) {
        case START_TIMER:
            return Object.assign({}, action.payload);
 
        default:
            return state;
    }
}