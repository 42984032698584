import {Component, OnInit, AfterViewInit} from "@angular/core";
import {routerTransition} from "@app/shared/utils/animations";

declare var $: any;

@Component({
    selector: "app-main-layout",
    templateUrl: "./main-layout.component.html",
    styles: [`
        .loader-div{
            text-align: center;
            height: 500px;
        }
        .fa-spin{
            font-size: 100px;  
            color: #1fc8db;
            margin-top: 15%;
        }`
    ],
    animations: [routerTransition]
})
export class MainLayoutComponent implements OnInit, AfterViewInit {
    constructor() {}

    ngOnInit() {}

    ngAfterViewInit() {
        $("#medius_tabs").tabs();
        $('#medius_tabs > div').attr("class", "");
    }

    getState(outlet) {
        if (!outlet.activatedRoute) return;
        let ss = outlet.activatedRoute.snapshot;
        // return unique string that is used as state identifier in router animation
        return (
            outlet.activatedRouteData.state ||
            (ss.url.length
                ? ss.url[0].path
                : ss.parent.url.length
                    ? ss.parent.url[0].path
                    : null)
        );
    }

    onActivate(eve: string) {
        if (!$.isEmptyObject(eve))
            $('.fa-' + eve).hide();
    }
}
