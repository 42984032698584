import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from './token.interceptor';

import {StoreModule} from "@ngrx/store";
import {NoteReducer} from './stores/note.store';
import {TimerReducer} from './stores/timer.store';
import {ClientReducer} from './stores/client.store';
import {EmployeeReducer} from './stores/employee.store';
import {ScheduleReducer} from './stores/schedule.store';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        CoreModule,
        StoreModule.forRoot({noteStore: NoteReducer, timerStore: TimerReducer, clientStore: ClientReducer, employeeStore: EmployeeReducer, scheduleStore: ScheduleReducer}),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
