import {Component, OnInit, ElementRef, EventEmitter, Output} from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-formvalidation',
    templateUrl: './formvalidation.component.html',
    styleUrls: ['./formvalidation.component.css']
})
export class FormvalidationComponent implements OnInit {

    @Output() validator = new EventEmitter();

    constructor(private el: ElementRef) {}

    ngOnInit() {
    }

    ngAfterViewInit() {
        let element = $(this.el.nativeElement.children[0]);
        element.find('input,textarea,select,checkbox').on('keypress', () => {
            this.validator.emit(true);
        });

        element.find('input,select,checkbox,radio,textarea').on('change', () => {
            this.validator.emit(true);
        });

        element.find('input,textarea').on('paste', () => {
            this.validator.emit(true);
        });

        element.find('input,textarea').on('cut', () => {
            this.validator.emit(true);
        });

        //         let element = $(this.el.nativeElement.children[0]);
        //        console.log(element);
        //        let self = this;
        //
        //        document.addEventListener('change', function () {
        //            self.validator.emit(true);
        //        });
        //        document.querySelector('select#agency_location').addEventListener('change', function () {
        //            console.log('change pressed');
        //                });
        //        document.addEventListener('input,textarea,select,checkbox').on('keypress', (        ) => {
        //            this.validator.emit(        true);
        //                });
        //
    }

}
