import {Directive, ElementRef, OnInit, Output, EventEmitter, Input} from '@angular/core';
declare var $: any;
declare var moment: any;

@Directive({
    selector: '[smartTimepicker]'
})
export class SmartTimepickerDirective implements OnInit {

    @Output() changeTime = new EventEmitter();
    @Input() custom_timepicker: boolean = false;
    constructor(private el: ElementRef) {}

    ngOnInit() {
    import('bootstrap-timepicker/js/bootstrap-timepicker.min.js').then(() => {
    this.render()
})
  }


//render() {
//    var _self = this;
//    let val = $(this.el.nativeElement).val();
//
////    $(this.el.nativeElement).timepicker({defaultTime: false}).on('changeTime.timepicker', function (e) {
//    $(this.el.nativeElement).timepicker({template: false, minuteStep: 1, defaultTime: false, explicitMode: true, disableFocus: true}).on('changeTime.timepicker', function (e) {
//
//        _self.changeTime.emit(_self.el.nativeElement);
//        //$(_self.el.nativeElement).timepicker({defaultTime: false});
//    });
//}

render() {
    if (this.custom_timepicker) {

        let old_time_val = '';

        let old_val = '';

        var _self = this;
        let val = $(_self.el.nativeElement).val();

        $(_self.el.nativeElement).siblings('input').on('focusin', function () {
            old_val = $(this).val();
        })

        $(_self.el.nativeElement).siblings('input').timepicker({defaultTime: false, template: false}).on('changeTime.timepicker', function (e) {
            if (old_val != $(this).val())
                _self.changeTime.emit($(this)[0]);
        });
        $(_self.el.nativeElement).siblings('input').on('keyup', function (event) {
            let key = event.key;
            if (key == '+' || key == 'p' || key == 'P') {
                $(this).val($(this).val().replace(key, " PM"));
            }
            else if (key == '-' || key == 'a' || key == 'A') {
                $(this).val($(this).val().replace(key, " AM"));
            }
            else if (key == '.') {
                $(this).val($(this).val().replace(key, ":"));
            }
            else if (key == "Enter") {
                $(this).trigger('blur');
                let index = $(this).parents('td').index();
                $(this).parents('tr').find('td').eq(index + 1).find('input').focus();
            }
            else if (key == "Escape") {
                $(this).val(old_val);
                $(this).trigger('blur');
            }
        })
        let value = '';
        $(_self.el.nativeElement).timepicker({defaultTime: false, explicitMode: true}).on('changeTime.timepicker', function (e) {
            value = e.time.value;
        });

        let time_picker = $(_self.el.nativeElement).timepicker().on('show.timepicker', function (e) {
            let self = $(this);

            if ($(this).parents('td').find('input').attr("data") == "true") {
                //            $(".bootstrap-timepicker-widget").css("margin-left", "-51px");
                $(".bootstrap-timepicker-widget").css("margin-left", "-3.2%");
            } else {
                //            $(".bootstrap-timepicker-widget").css("margin-left", "-98px");
                $(".bootstrap-timepicker-widget").css("margin-left", "-6.2%");
            }

            $('.bootstrap-timepicker-widget').children('.custom-timepicker').remove();
            $('.bootstrap-timepicker-widget').append(`<div class="custom-timepicker">
                                                        <div class="min-btn" style="margin-top:15px;margin-left:12px">
                                                            <a class="btn btn-primary btn-xl timepicker-one" style="opacity:0.6;">1 min</a>
                                                            <a class="btn btn-primary btn-xl timepicker-five" style="opacity:100;">5 min</a>
                                                            <a class="btn btn-primary btn-xl timepicker-fifteen" style="opacity:0.6;">15 min</a>
                                                        </div>
                                                        <br>
                                                        <div class="select-close">
                                                            <a class="btn btn-success btn-xl pull-left timepicker-save" style="width: 65px;">Save</a>
                                                            <a style="width: 65px;" class="btn btn-danger btn-xl pull-right timepicker-cancel">Cancel</a>
                                                        </div>
                                                      </div>`)
            $('.bootstrap-timepicker-widget .timepicker-one').bind('click', function () {
                let before_change_value = self.timepicker().val();
                self.timepicker('remove');
                let index = $(this).index();
                self.timepicker({explicitMode: true, minuteStep: 1});
                self.timepicker('showWidget');
                self.timepicker('setTime', before_change_value);

                //            $('.min-btn a').removeClass('btn-primary').addClass('btn-info');
                //            $('.min-btn a').eq(index).removeClass('btn-info').addClass('btn-primary');

                $('.min-btn a').css("opacity", "0.6");
                $('.min-btn a').eq(index).css("opacity", "100");
            });

            $('.bootstrap-timepicker-widget .timepicker-five').bind('click', function () {
                let before_change_value = self.timepicker().val();
                self.timepicker('remove');
                let index = $(this).index();
                self.timepicker({defaultTime: false, explicitMode: true, minuteStep: 5});
                self.timepicker('showWidget');
                self.timepicker('setTime', before_change_value);

                //            $('.min-btn a').removeClass('btn-primary').addClass('btn-info');
                //            $('.min-btn a').eq(index).removeClass('btn-info').addClass('btn-primary');
                $('.min-btn a').css("opacity", "0.6");
                $('.min-btn a').eq(index).css("opacity", "100");
            });

            $('.bootstrap-timepicker-widget .timepicker-fifteen').bind('click', function () {
                let before_change_value = self.timepicker().val();
                self.timepicker('remove');
                let index = $(this).index();
                self.timepicker({defaultTime: false, explicitMode: true, minuteStep: 15});
                self.timepicker('showWidget');
                self.timepicker('setTime', before_change_value);

                //            $('.min-btn a').removeClass('btn-primary').addClass('                btn-info');
                //            $('.min-btn a').eq(index).removeClass('btn-info').addClass('btn-primary');

                $('.min-btn a').css("opacity", "0.6");
                $('.min-btn a').eq(index).css("opacity", "100");

            });
            $('.bootstrap-timepicker-widget .timepicker-cancel').bind('click', function () {
                self.timepicker('hideWidget');
                $(this).parents('.custom-timepicker').remove();
                $(this).unbind('click')
            })
            $('.bootstrap-timepicker-widget .timepicker-save').bind('click', function () {
                self.timepicker('hideWidget');
                if (old_time_val != value) {
                    _self.changeTime.emit({value: value});
                }
                $(this).parents('.custom-timepicker').remove();
                $(this).unbind('click');
            })
            if ($(this).siblings('input').val() == "") {
                let index = $(this).parents('td').index();
                if (index == 2)
                    $(this).timepicker('setTime', '8:00 AM');
                else {
                    let val = $(this).parents('tr').find('td').eq(index - 1).find('input').val();
                    if (index == 4) {
                        $(this).timepicker('setTime', moment(val, "h:mma").add(1, "hours").format("h:mm A"));
                    }
                    else {
                        $(this).timepicker('setTime', moment(val, "h:mma").add(3, "hours").format("h:mm A"));
                    }
                }
            }
            else {
                old_time_val = $(this).siblings('input').val();
                $(this).timepicker('setTime', old_time_val);
            }

        });

    } else {
        var _self = this;
        let val = $(this.el.nativeElement).val();
        $(this.el.nativeElement).timepicker('setTime', val).on('changeTime.timepicker', function (e) {
            //        $(this.el.nativeElement).timepicker({template: false, minuteStep: 1, defaultTime: false, explicitMode: true, disableFocus: true}).on('changeTime.timepicker', function (e) {
            _self.changeTime.emit(_self.el.nativeElement);
            //$(_self.el.nativeElement).timepicker({defaultTime: false});
        });
    }
}
}
