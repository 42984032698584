import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {NotificationService} from '@app/core/services';
import {
    HttpRequest,
    HttpClient,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize, tap, catchError, map} from 'rxjs/operators';
import {Store, select} from "@ngrx/store";
import {Globals} from '@app/app.globals';
declare var $: any;

@Injectable({providedIn: 'root'})
export class TokenInterceptor implements HttpInterceptor {
    current_time = Date.now();
    clearTimer: any;
    logoutTime: any;
    timeoutInterval: any;
    unsub_store: any;
    constructor(private http: HttpClient, private router: Router, private store: Store<any>, private gs: Globals, private notification: NotificationService) {
        this.unsub_store = this.store.pipe(select('timerStore'))
            .subscribe((data: any) => {
                if (data.clear) {
                    this.clearAll();
                    return;
                }

                if (data.startTimer) {
                    this.logoutTime = data.logoutTime;
                    this.timeoutInterval = data.timeoutInterval;
                    this.initListener();
                    this.initInterval();
                }
            });
    }

    ngOnDestroy() {
        this.unsub_store.unsubscribe();
        clearInterval(this.clearTimer);
    }

    private applyCredentials(req: any) {
        return req.clone({
            headers: req.headers.set("Authorization", sessionStorage.getItem('adc_token') || '')
        });
    };

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.applyCredentials(req))
            .pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    if (event.body.per_auth == false) {
                        this.notification.smallBox({
                            title: "Not Authorized",
                            content: event.body.message,
                            color: "#A90329",
                            iconSmall: "fa fa-thumbs-down bounce animated",
                            timeout: 4000
                        });
                        return;
                    }
                }
            }),
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        sessionStorage.removeItem('adc_token');
                        sessionStorage.setItem('adc_token', error.error.refresh_token);
                        return next.handle(this.applyCredentials(req));
                    }
                    else if (error.status === 400) {
                        this.clearAll();
                        return;
                    }
                }
            }),
            // Log when response observable either completes or errors
            finalize(() => {

            })
            );
    }

    reset: any;
    initListener() {
        let self = this;
        this.reset = function () {
            self.current_time = Date.now();
        }
        document.body.addEventListener('click', this.reset, false);
        document.body.addEventListener('keyup', this.reset, false);
    }

    initInterval() {
        let token_time = 0;
        let logoutTime = this.logoutTime * 60 * 1000;
        this.clearTimer = setInterval(() => {
            this.check();
            token_time = token_time + this.timeoutInterval;
            if (token_time == logoutTime) {
                this.http.get(this.gs.api_url + "tokenRefresh")
                    .pipe(
                    map(res => res),
                    catchError((err) => {
                        if (err) {
                            if (err.status === 401) {
                                token_time = 0;
                                let _body = JSON.parse(err._body);
                                sessionStorage.removeItem('adc_token');
                                sessionStorage.setItem('adc_token', _body.refresh_token);
                            }
                            return Observable.throw('token refreshhed');
                        }
                    }))
                    .subscribe((res: any) => {
                        if (res.success)
                            token_time = 0;
                    });
            }
        }, this.timeoutInterval);
    }

    check() {
        const now = Date.now();
        const timeleft = this.current_time + (this.logoutTime * 60 * 1000);
        const diff = timeleft - now;
        const isTimeout = diff < 0;
        if (isTimeout) {
            this.clearAll();
        }
    }

    clearAll() {
        document.body.removeEventListener('click', this.reset, false);
        document.body.removeEventListener('keyup', this.reset, false);
        var acc = this.gs.account_name;
        var user = this.gs.user_name;
        var user_email = this.gs.user_email;
        var obj: any = {acc: acc, user: user, user_email: user_email};
        var lockout_token = window.btoa(JSON.stringify(obj));
        clearInterval(this.clearTimer);
        $(".modal, .in").modal("hide");
        sessionStorage.clear();
        sessionStorage.setItem('lockout_token', lockout_token);
        this.router.navigate(['/locked']);
    }
}