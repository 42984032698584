import { Action } from '@ngrx/store';

export const ADD_NOTE = 'ADD_NOTE';

const initialState = {
    CID: 0,
    note_type: "",
    note_subtype: "",
    quick_link: ""
};

export function NoteReducer(state = initialState, action: Action) {
    switch (action.type) {
        case ADD_NOTE:
            return action.payload;

        default:
            return state;
    }
}