import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '@app/core/services';
import {Globals} from '@app/app.globals';
import {Store} from "@ngrx/store";
import {START_TIMER} from '@app/stores/timer.store';
import {map} from 'rxjs/operators';
declare var $: any;

@Component({
    selector: 'sa-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

    api = this.gs.api_url;
    messages: boolean = true;
    constructor(private router: Router, private http: HttpClient, private gs: Globals, private notificationService: NotificationService, private store: Store<any>) {
    }

    ngOnInit() {
    }


    searchMobileActive = false;

    toggleSearchMobile() {
        this.searchMobileActive = !this.searchMobileActive;

        $('body').toggleClass('search-mobile', this.searchMobileActive);
    }

    onSubmit() {
        this.router.navigate(['/miscellaneous/search']);

    }


    showPopup() {
        this.notificationService.smartMessageBox({
            title:
            "<i class='fa fa-sign-out txt-color-orangeDark'></i> Logout <span class='txt-color-orangeDark'><strong>" + this.gs.user_name + "</strong></span> ?",
            content:
            "You can improve your security further after logging out by closing this opened browser",
            buttons: "[No][Yes]"

        }, (ButtonPressed) => {
            if (ButtonPressed == "Yes") {
                this.logout()
            }
        });
    }

    logout() {
        var account = this.gs.account_name;
        sessionStorage.clear();
        //        this.compiler.clearCache();
        this.store.dispatch({type: START_TIMER, payload: {clear: true}});
        //        $('#locked_screen').modal('hide');
        //        $("#login_screen").modal({backdrop: 'static', keyboard: false});
        this.router.navigate(['/' + account]);
        //        window.location.reload();
    }

}
