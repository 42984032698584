import {NgModule} from "@angular/core";
import {Routes, RouterModule, PreloadAllModules} from "@angular/router";

import {MainLayoutComponent} from "./shared/layout/app-layouts/main-layout.component";
import {AuthLayoutComponent} from "./shared/layout/app-layouts/auth-layout.component";

import {CustomPreloadingWithDelayStrategy} from './custom-preloading';

import {AuthGuard} from './auth.guard';

const routes: Routes = [
    {
        path: "",
        component: AuthLayoutComponent,
        loadChildren: "app/features/auth/auth.module#AuthModule",
        //        canDeactivate: [AuthGuard],
    },
    {
        path: "",
        component: MainLayoutComponent,
        data: {pageTitle: "Home"},
        canActivate: [AuthGuard],
        children: [
            {
                path: ":prefix",
                loadChildren: "app/features/medius.module#MediusModule",
                data: {pageTitle: "Dashboard"}
            }
        ]
    },
    //    {path: "**", redirectTo: "miscellaneous/error404"}


];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: CustomPreloadingWithDelayStrategy})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
