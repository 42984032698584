export const config: any = {
    assets: {
        medius: {
            us: {
                api_url: "http://adcapi.showmywork.in/",
                socket_url : "https://pubsub.medius.us/",//"http://52.6.122.97:3000/";
                medius_print_url : "https://angular.medius.us/report.html",
                img:'assets/img/adc-logo.png'
            },
            io:{
                url: "https:v2.medius.io",
            }
        }
    }
}


