import {Injectable} from '@angular/core';
import {config} from './app.config';
import {Store} from "@ngrx/store";
import {START_TIMER} from './stores/timer.store';
declare var moment: any;

@Injectable({providedIn: 'root'})
export class Globals {
    api_url: any = "http://adcapi.showmywork.in/";
    socket_url: any = "http://52.6.122.97:3000/"; //"https://pubsub.medius.us/"
    medius_print_url: any = "https://angular.medius.us/report.html";
    account: string // 1006_demo        sessionStorage.getItem('current_agency'); //remove
    current_agency: string;// 1006_demo           = sessionStorage.getItem('current_agency'); //remove 
    account_name: string = 'hse';// shootmsg                   = localStorage.getItem('account_name'); //remove
    //    guid = localStorage.getItem('guid'); //remove
    db_sm_length = 10;
    db_md_length = 25;
    db_lg_length = 50;
    db_xl_length = 100;
    client_update_tab: any = [];
    client_tabs: any = [];
    employee_update_tab: any = [];
    employee_tabs: any = [];
    manage_table_tab: any = [];

    index = 0;
    dateFormate = "yy-mm-dd";
    yearRange = "-100:+100";
    changeMonth = true;
    changeYear = true;
    auth_title = "Not Authorized";
    auth_msg = "You Are Not Authorized..!";

    user_name: string;
    user_email: string;
    user_guid: string;
    role_permissions: any = [];

    extExpireAuthFlag = false;
    extActiveAuthFlag = false;
    extClosedAuthFlag = false;
    
    defaultDays;

    constructor(private store: Store<any>) {
        var obj = config.assets.medius.us;
        this.api_url = obj.api_url;
        this.socket_url = obj.socket_url;
        this.medius_print_url = obj.medius_print_url;
        if (sessionStorage.getItem('medius_token')) {
            this.medToken(sessionStorage.getItem('medius_token'));
        }
    }

    medToken(medius_token: any) {
        var base64Url = medius_token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var token = JSON.parse(window.atob(base64));
        // console.log(token);
        if(token.defaultdays){
            this.defaultDays = parseInt(token.defaultdays.split("+")[1]);
        }
        
        this.user_name = token.username;
        this.user_guid = token.user_guid;
        this.store.dispatch({type: START_TIMER, payload: {startTimer: true, logoutTime: token.token_timeout, timeoutInterval: token.interval_timeout}});
    }

    getClientTabObj() {
        return {0: "Client Profile", 1: "Notes", 2: "Authorizations", 3: "Schedules", 4: "Time & Attendance", 5: "Visits", 6: "Forms", 7: "Invoice Log", 8: "Claim Log", 9: "Map", 10: "Timeline"};
    }

    generatePassword() {
        if (typeof l === 'undefined') {var l = 8;}
        var c = 'abcdefghijknopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ012345679',
            n = c.length,
            p = '!@#$+-*&_',
            o = p.length,
            r = '',
            n = c.length,
            s = Math.floor(Math.random() * (p.length - 1));

        for (var i = 0; i < l; ++i) {
            if (s == i) {
                r += p.charAt(Math.floor(Math.random() * o));
            } else {
                r += c.charAt(Math.floor(Math.random() * n));
            }
        }
        return r;
    }

    getCommentArray(comment, note_type = "DEFAULT") {
        var comments = comment;
        var commentheightpx = this.getWidthOfText(comments);
        var comments_array = [];
        var first_pg_ht = 100;
        if (note_type == "change_status_note")
            first_pg_ht = 1500;
        var next_pg_ht = 1525;
        var idx = 0;
        if (commentheightpx > first_pg_ht) // break comments into multiple parts
        {
            var comm_substr_startidx = 0;
            var firstpage_comm = 600;
            if (note_type == "change_status_note")
                firstpage_comm = 7700;
            var reducecharsby = 10;
            var secondpg_chars = 8000;
            var comments_strlen = comments.length;
            var comments_substr, comments_2_htpx, nextpage_comm;
            while (true) {
                if (idx == 0) {
                    // sub string the comment -- take 200 chars and pass to getWidthOfText
                    comments_substr = comments.substring(comm_substr_startidx, firstpage_comm); // 0 , 200
                    comments_array[idx] = comments_substr;
                    //                    console.log('1 comm = ' + comments_array[idx]);
                    comments_2_htpx = this.getWidthOfText(comments_substr);
                    //                    console.log('px :  ' + comments_2_htpx);
                    if (comments_2_htpx > first_pg_ht) // it means --reduce sub string length
                    {
                        firstpage_comm = firstpage_comm - reducecharsby;
                        continue;
                    }
                    else // first page comment is done. now 2nd page comment
                    {
                        idx++;
                        comm_substr_startidx = firstpage_comm; // 200
                        nextpage_comm = secondpg_chars + comm_substr_startidx; // 6200
                        //                        console.log('11 comm = ' + comments_array[idx]);
                    }
                }
                else { // this
                    comments_substr = comments.substring(comm_substr_startidx, nextpage_comm); // 200 , 6200  -- 6200 , 12200
                    //                    console.log('11 comm = ' + comments_substr);
                    if (comments_substr.length == 0)
                        break;

                    comments_array[idx] = comments_substr;
                    //                    console.log("---" + comments_array[idx]);
                    comments_2_htpx = this.getWidthOfText(comments_substr);
                    //                    console.log("px" + comments_2_htpx);
                    if (comments_2_htpx > next_pg_ht) {
                        nextpage_comm = nextpage_comm - reducecharsby;
                        continue;
                    }
                    else {  // 2nd page comments got it. now next pages
                        comments_array[idx] = comments_substr;
                        idx++;
                        comm_substr_startidx = nextpage_comm; // 6200 -- 12200
                        nextpage_comm = secondpg_chars + comm_substr_startidx; // 12200  -- 18200
                        //                        console.log('22 comm = ' + comments_array[idx]);
                    }
                }
                continue;
            }

        } else {
            comments_array[idx] = comments;
        }
        return comments_array;
    }

    e;
    getWidthOfText(txt) {
        var fontname = "arial";
        var fontsize = 10;
        this.e = document.createElement('div'); // Create dummy div
        this.e.style.fontSize = fontsize; // Set font-size
        this.e.style.fontFamily = fontname; // Set font-face / font-family
        this.e.style.width = "540px";
        this.e.innerHTML = txt; // Set text
        document.body.appendChild(this.e);
        var h = this.e.offsetHeight; // Get width NOW, since the dummy span is about to be removed from the document
        document.body.removeChild(this.e); // Cleanup
        return h;
    }

    authServiceDateValidation(obj) {
        var start = moment(obj.start),
            end = moment(obj.end),
            ser_start = moment(obj.start_date),
            ser_end = moment(obj.end_date);

        if (!(start <= ser_start && ser_end <= end)) {
            return "Start date & end date must be in between start date & end date of selected authorization";
        }
    }

    openJasperReportWindow(reportkeyname: any, pdf: any) {
        try {
            sessionStorage.setItem(reportkeyname, pdf);
            window.open(this.medius_print_url + "?guid=" + reportkeyname);
        }
        finally {
            sessionStorage.removeItem(reportkeyname);
        }
    }
}