import { Action } from '@ngrx/store';

export const Update_Employee = 'Update_Employee';

const initialState = {
    update_employee: false
};

export function EmployeeReducer(state = initialState, action: Action) {
    switch (action.type) {
        case Update_Employee:
            return action.payload;
        default:
            return state;
    }
}