import {Component, OnInit, AfterViewInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {START_TIMER} from '@app/stores/timer.store';
import {NotificationService} from '@app/core/services';
import {Globals} from '@app/app.globals';
import {LayoutService} from "@app/core/services/layout.service";

declare var $: any;

@Component({

    selector: 'sa-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit, AfterViewInit {

    prefix = "/hse";
    clearTimer: any;

    modal_id: any;
    modal_title: string;
    val_sec = this.gs.role_permissions;

    constructor( private router: Router, private gs: Globals, private store: Store<any>, private notificationService: NotificationService, private layoutService: LayoutService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        const self = this;
        $(document).on("click", "ul#medius_leftmenu_navigation li a", function () {
            if ($(window).width() < 980) {
                self.layoutService.onCollapseMenu();
            }
            var current_tab = $(this).data('tab') || "",
                tab_title = $(this).data('title') || "",
                setting = $(this).data('setting') || "",
                mealsnutrition = $(this).data('mealsnutrition') || "",
                action = $(this).data('action') || "",
                close = $(this).data('close') || "",
                claims = $(this).data('claims') || "",
                popup = $(this).data('pop') || "",
                pid = $(this).data('pid') || "",
                timeAtt = $(this).data('time-attendance') || "",
                mealApp = $(this).data('nutrition-center') || "";

            if (close) {
                $('ul#medius_leftmenu_navigation li.open a[href="#"]').trigger('click');
            }

            if (popup && !current_tab) {
                if ($("ol#medius_breadcrumbs #medius-" + popup.toLowerCase()).length == 0) {
                    var pre_active = $("ol#medius_breadcrumbs").find("li.active").attr('id');
                    $('#' + pid).on('hidden.bs.modal', function (e) {
                        if ($("#medius_tabs li.ui-state-active a[href='#medius-" + popup.toLowerCase() + "']").length == 0) {
                            $("ol#medius_breadcrumbs li#medius-" + popup.toLowerCase()).remove();
                            $("ol#medius_breadcrumbs li#" + pre_active).addClass("active");
                        }
                    })
                    $("ol#medius_breadcrumbs").append('<li id="medius-' + popup.toLowerCase() + '">' + popup + '</li>');
                    $("ol#medius_breadcrumbs li#" + pre_active).removeClass("active");
                    $("ol#medius_breadcrumbs #medius-" + popup.toLowerCase()).addClass("active");
                    return;
                }
                current_tab = popup.toLowerCase();
            }

            $('ul#medius_leftmenu_navigation li').removeClass('active');
            $("ol#medius_breadcrumbs li").removeClass("active");
            if (!$("ol#medius_breadcrumbs #medius-" + current_tab).length && tab_title)
                $("ol#medius_breadcrumbs").append('<li id="medius-' + current_tab + '">' + tab_title + '</li>');

            $("ol#medius_breadcrumbs #medius-" + current_tab).addClass("active");
            $('#medius_tabs a[href="#medius-' + current_tab + '"]').trigger('click');
            if (setting) {
                $("ul#settings_nav li").removeClass("active");
                $(this).parent().addClass("active");
                self.clearTimer = setInterval(() => {
                    if ($('#settings_tabs a[href="#' + setting + '"]')[0]) {
                        clearInterval(self.clearTimer);
                        $('#settings_tabs a[href="#' + setting + '"]')[0].click();
                    }
                }, 10)
            }
            if (claims) {
                $("ul#claims_nav li").removeClass("active");
                $(this).parent().addClass("active");
                self.clearTimer = setInterval(() => {
                    if ($('#claims_tabs a[href="#' + claims + '"]').length) {
                        clearInterval(self.clearTimer);
                        $('#claims_tabs a[href="#' + claims + '"]').trigger('click');
                    }
                }, 10)
            }

            if (action == "addClient") {
                $("ul#client_nav li").removeClass("active");
                $(this).parent().addClass("active");
                self.clearTimer = setInterval(() => {
                    if ($("button#mdAddClient").length) {
                        clearInterval(self.clearTimer);
                        $("button#mdAddClient").trigger("click");
                    }
                }, 500)
            }

            if (action == "Clients") {
                $("ul#client_nav li").removeClass("active");
                $(this).parent().addClass("active");
                $("#client_tabs").tabs("option", "active", 0);
            }

            if (!$('ul#medius_leftmenu_navigation li a#nav-medius-' + current_tab).parent().hasClass("active")) {
                $('ul#medius_leftmenu_navigation li a#nav-medius-' + current_tab).parent().addClass("active")
            }

            $("div#manage-all-tables").show();
            $("#current-mtable").hide();
        });
    }

    activateClientTab(i) {
        $("#medius_tabs").tabs("option", "active", i);
    }

    popupName(id, name) {
        this.modal_id = id;
        this.modal_title = name;
    }

    logout() {

        this.notificationService.smartMessageBox({
            title: "<i class='fa fa-sign-out txt-color-orangeDark'></i> Logout <span class='txt-color-orangeDark'><strong>" + $('#show-shortcut').text() + "</strong></span> ?",
            content: "You can improve your security further after logging out by closing this opened browser",
            buttons: '[No][Yes]'

        }, (ButtonPressed) => {
            if (ButtonPressed == "Yes") {
                var account = this.gs.account_name;
                sessionStorage.clear();
                //        this.compiler.clearCache();
                this.store.dispatch({type: START_TIMER, payload: {clear: true}});
                this.router.navigate(['/' + account])
                //                $('#locked_screen').modal('hide');
                //                $("#login_screen").modal({backdrop: 'static', keyboard: false});
            }
        });
    }

    lockout() {
        this.store.dispatch({type: START_TIMER, payload: {clear: true}});
    }

}
