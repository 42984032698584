import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormvalidationComponent} from './formvalidation.component';
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [FormvalidationComponent],
    exports: [
        FormvalidationComponent
    ]
})
export class FormvalidationModule {}
