import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {Router, NavigationEnd} from "@angular/router";
import {filter} from 'rxjs/operators';

@Component({
    selector: 'sa-route-breadcrumbs',
    template: `
        <ol class="breadcrumb" id="medius_breadcrumbs">
           <li id="medius-home" class="active">Home</li>
        </ol>
  `,
    styles: []
})
export class RouteBreadcrumbsComponent implements OnInit, AfterViewInit, OnDestroy {

    public items: Array<string> = [];
    private sub

    constructor(
        private router: Router
    ) {}

    ngOnInit() {
        this.extract(this.router.routerState.root)
        this.sub = this.router.events.pipe(
            filter(e => e instanceof NavigationEnd)
        )

            .subscribe(v => {
                this.items = [];
                this.extract(this.router.routerState.root)
            });

    }
    ngAfterViewInit() {
        $(document).on('click', "ol#medius_breadcrumbs li", function () {
            var ID = $(this).attr("id");
            $("ol#medius_breadcrumbs li").removeClass("active");
            $('#medius_tabs a[href="#' + ID + '"]').trigger('click');
            $('ul#medius_leftmenu_navigation li').removeClass('active');
            if ($('ul#medius_leftmenu_navigation li.open a#nav-' + ID).length !== 1) {
                $('ul#medius_leftmenu_navigation li.open a[href="#"]').trigger('click');
                $('ul#medius_leftmenu_navigation a#nav-' + ID).trigger('click');
            }
            if (!$('ul#medius_leftmenu_navigation li a#nav-' + ID).parent().hasClass("active")) {
                $('ul#medius_leftmenu_navigation li a#nav-' + ID).parent().addClass("active")
            }
            $('ul#medius_leftmenu_navigation a#sub-nav-' + ID).parent().addClass("active");
            $(this).addClass("active");
        });
    }


    ngOnDestroy(): void {
        this.sub.unsubscribe()
    }


    extract(route) {
        let pageTitle = route.data.value['pageTitle'];
        if (pageTitle && this.items.indexOf(pageTitle) == -1) {
            this.items.push(route.data.value['pageTitle'])
        }
        if (route.children) {
            route.children.forEach(it => {
                this.extract(it)
            })
        }
    }


}
