import { Component, OnInit, Input,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Router } from "@angular/router";
import { Store } from '@ngrx/store';
import { ADD_NOTE } from '@app/stores/note.store';
import { Globals } from '@app/app.globals';

declare var $: any;

@Component({
    selector: 'app-addnotepopup',
    templateUrl: './addnotepopup.component.html',
    styleUrls: ['./addnotepopup.component.css']
})
export class AddnotepopupComponent implements OnInit {
    @Input() modal_id: string;
    @Input() modal_title: string;
    clients;
    header;
    clientNote: any = {client_name:''};
    note_type = "";
    subnote_types = [];
     btnBanner: boolean = true;
     
     @ViewChild('anp') frmobj: any;
    constructor(private router: Router, private http: HttpClient, private store: Store<any>, private gs: Globals) {
    }

    ngOnInit() {
        this.http.get(this.gs.api_url + 'client/getClientList')
            .subscribe((res: any) => {
                this.clients = res.data;
            })
    }


    getNoteSubtypes() {
        this.http.get(this.gs.api_url + 'notes/getSubNoteTypeOptions/' + this.note_type)
            .subscribe((res: any) => {
                this.subnote_types = res.data;
            })
    }

    onSubmit(form: NgForm) {
        var CID = (form.value.client).split(" - ")[0], addNote = {            
            CID: CID,
            note_type: form.value.note,
            note_subtype: form.value.sub_note
        };
        this.router.navigate(['/' + this.gs.account_name, { outlets: { client: 'client' } }]);
        $("a#client_view_clients").trigger("click");
        $("#addnote_popup").modal("hide");
        this.store.dispatch({ type: ADD_NOTE, payload: addNote });
        if ($("div#client_tabs").length) {
            $('a[data-rcid="' + CID + '"]').trigger("click");
            $("#client_detail_tabs_" + CID).tabs("option", "active", 1);
        }
    }

    quickLink(form: NgForm) {
        var CID = (form.value.client).split(" - ")[0], quick_link = {
            CID: CID,
            quick_link: this.modal_title
        };
        
        this.router.navigate(['/' + this.gs.account_name, { outlets: { client: 'client' } }]);
        $("a#client_view_clients").trigger("click");
        $("#" + this.modal_id).modal("hide");
        this.store.dispatch({ type: ADD_NOTE, payload: quick_link });
        if ($("div#client_tabs").length) {
            $('a[data-rcid="' + CID + '"]').trigger("click");
            var index = $("#client_detail_tabs_" + CID + " > ul > li[aria-controls='md-" + this.modal_title.toLowerCase() + "-" + CID + "']").index()
            $("#client_detail_tabs_" + CID).tabs("option", "active", index);
        }
    }
    
    closeClientNotePopup(){

        this.frmobj.resetForm();
        $('#addnote_popup').modal('hide');
        this.clientNote['client_name'] ='';
        

    }
      validator(e, validationType) {
       if (e) {
           if (validationType == "banner")
               this.btnBanner = false;
       }
   }

}
