import {Injectable} from '@angular/core';
import {JsonApiService} from '@app/core/services';
import {Globals} from '@app/app.globals';
import {HttpClient} from '@angular/common/http';

import {map} from 'rxjs/operators';

@Injectable()
export class ActivitiesService {

    url: string;

    api = this.globals.api_url + 'task/';
    constructor(private http: HttpClient, private jsonApiService: JsonApiService, private globals: Globals) {
        this.url = '/activities/activities.json';
    }


    getActivities() {
        return this.jsonApiService.fetch(this.url)
    }

    getTasks() {
        // return this.http.get(this.api + 'getNewTasks').pipe(map(res => res));
    }

}
