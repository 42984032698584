import {Component, HostListener} from '@angular/core';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
declare var $: any;

@Component({
    selector: 'app-root',
    template: `<router-outlet (activate)='onActivate()'>
        <div id="med_loading">
            <div style="height:100%;text-align:center">        
                <img src="assets/img/adc-logo.png" style="width: 25%; margin-top: 15%;"/>
                <div id="nlpt"></div>
                <div class="med_msg">Loading&hellip;</div>
            </div>
        </div>
    </router-outlet>`,
})
export class AppComponent {
    title = 'sa';

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (confirm("You have unsaved changes! If you leave, your changes will be lost.")) {
            return true;
        } else {
            return false;
        }
    }


    constructor(private router: Router) {

        router.events.subscribe((event: Event) => {

            if (event instanceof NavigationStart) {
                // Show loading indicator
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator
                // Present error to user
            }
        });

    }

    onActivate() {
        $('#med_loading').remove();
    }

}
