import {Injectable} from '@angular/core';
import {Router, CanActivate,} from '@angular/router';
import {Globals} from './app.globals';
//import {Observable} from 'rxjs';


//export interface CanRouteDeactivate {
//    canDeactivate(nextState: string): Observable<boolean> | Promise<boolean> | boolean;
//}


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate  {

    constructor(private gs: Globals, private router: Router) {}

    canActivate() {
//        console.log("asfajsfh");
        if (!sessionStorage.getItem('adc_token')) {
            sessionStorage.clear();
            this.router.navigate(['/' + this.gs.account_name]);
            return false;
        }
        else {
            return true;
        }
    }

//    canDeactivate() {
//        console.log('2424');
//        if (!sessionStorage.getItem('adc_token')) {
//            console.log('if');
//            return false;
//        }
//        else {
//            return true;
//        }
//    }


}
