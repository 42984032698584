import {Action} from '@ngrx/store';

export const Update_Schedule = 'Update_Schedule';

const initialState = {
    sid: 0,
    cid: 0
};

export function ScheduleReducer(state = initialState, action: Action) {
    switch (action.type) {
        case Update_Schedule:
            return action.payload;

        default:
            return state;
    }
}